import React, { PureComponent, Component } from 'react';
import Routes from './routes';
import DocumentTitle from 'react-document-title';
import SiderCustom from './components/SiderCustom';
import HeaderCustom from './components/HeaderCustom';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { Layout, message, Skeleton } from 'antd';
import { connectAlita } from 'redux-alita';
import events from "./events";
import { getCasLoginByTicket, getLicenseKeyfile } from '../src/axios/index';
import cookie from 'react-cookies'
import i18n from "i18next";
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import koKR from 'antd/lib/locale-provider/ko_KR';
import moment from 'moment/moment.js';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
moment.locale('en');

const { Content } = Layout;

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            title: '',
            loginsuccess: false,
            keyfileSuccess: false,//loginsuccess,keyfileSuccess 同时为true 进入菜单栏
            locale: enUS,
            al: 'en-US',
            type: '',
        }
    }

    componentWillMount() {
        this.getServerConfig();
        this.navLang()
    }
    componentWillReceiveProps(nextprops) {
        const { al } = nextprops;
        if (!!al && !!al.data && this.state.al != al) {

            if (al.data == 'en-US') {
                this.setState({
                    locale: enUS
                }, () => {
                })
                setTimeout(() => {
                    moment.locale('en');
                }, 100)
            } else if (al.data == 'zh-CN') {
                this.setState({
                    locale: zhCN
                }, () => {
                })
                setTimeout(() => {
                    moment.locale('zh-cn');
                }, 100)
            } else {
                this.setState({
                    locale: koKR
                }, () => {
                })
                setTimeout(() => {
                    moment.locale('ko');
                }, 100)
            }
        }
    }

    init = () => {
        this.getClientWidth();
        window.onresize = () => {
            // console.log('屏幕变化了');
            this.getClientWidth();
        }

        events.on("update", () => {
            // console.log("33")
            this.forceUpdate();
        })
    }

    getServerConfig = () => {
        const { setAlitaState } = this.props;
        setAlitaState({
            stateName: 'serverConfig',
            funcName: 'getServerConfig',
        }).then(res => {
            console.info("serverConfig :", res.data.data)
            localStorage.setItem('serverConfig', JSON.stringify(res.data.data))
            this.setState({
                type: res.data.data.deploymentMode
            })
        });
    };

  
  


    getClientWidth = () => { // 获取当前浏览器宽度并设置responsive管理响应式
        const { setAlitaState } = this.props;
        const clientWidth = window.innerWidth;
        setAlitaState({ stateName: 'clientWidth', data: window.innerWidth });
        setAlitaState({ stateName: 'clientHeight', data: window.innerHeight });
        // setAlitaState({ stateName: 'responsive', data: { isMobile: clientWidth <= 992 } });
        if (clientWidth <= 900) {
            this.setState({
                collapsed: true,
            });
        } else {
            this.setState({
                collapsed: false,
            });
        }
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    // 同步浏览器语言
    navLang = () => {
        var type = navigator.appName;
        if (type == "Netscape") {
            var lang = navigator.language;
        } else {
            var lang = navigator.userLanguage;//支持IE5+ 
        };
        var lang = lang.substr(0, 2);
        if (lang == "zh") {
            lang = 'zh-CN'
        } else if (lang == "en") {
            lang = 'en-US'
        } else {
            lang = 'en-US'
        }
        lang = (cookie.load('al') == 'en-US' || cookie.load('al') == 'zh-CN' || cookie.load('al') == 'ko-KR') ? cookie.load('al') : lang
        i18n.init({
            lng: lang
        });
        var url = window.location.host.split('.')
        var domain = url[1] + '.' + url[2]
        cookie.save('al', lang, { path: '/', domain: domain })
        this.setState({
            al: lang
        })
        if (lang == 'zh-CN') {
            this.setState({
                locale: zhCN
            })
            moment.locale('zh-cn');
        } else {
            this.setState({
                locale: enUS
            })
            moment.locale('en');
        }
    }

    render() {
        const { title, loginsuccess, isInitialization, keyfileSuccess, locale, type } = this.state;
        const { auth = { data: {} }, responsive = { data: {} } } = this.props;
        // isInitialization? <Redirect to={'/initialization'} />:
        return (
            <LocaleProvider locale={locale}>
                <DocumentTitle title={title}>
                            <Layout>
                                {!responsive.data.isMobile && <SiderCustom collapsed={this.state.collapsed} toggle={this.toggle} />}
                                <Layout className={this.state.collapsed ? 'mg-left-80' : 'mg-left-300'}>
                                    <HeaderCustom toggle={this.toggle} collapsed={this.state.collapsed} user={auth.data || {}} />
                                    <Content style={{ overflow: 'auto', height: '100%', marginTop: '65px' }}>
                                        <Routes auth={auth} />
                                    </Content>
                                </Layout>
                            </Layout>
                </DocumentTitle>
            </LocaleProvider>
        );
    }
}

export default withRouter(connectAlita(['auth', 'responsive', "serverConfig", "licenseMode", 'licenseRestriction', 'userIDMode', 'winAll', 'al'])(App));
