import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_US from './locales/translation-en-US';
import zh_CN from './locales/translation-zh-CN';
import ko_KR from './locales/translation-ko-KR';

const resources = {
  'zh-CN': {
    common: {
      ...zh_CN
    }
  },
  'en-US': {
    common: {
      ...en_US
    }
  },
  'ko-KR': {
    common: {
      ...ko_KR
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en-US",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultTransParent: 'div',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', "span"],
  });

export default i18n;