import React from 'react';
import { withTranslation } from 'react-i18next';
import { connectAlita } from 'redux-alita';
import { message, Table, Button, Input, Modal, Form, Radio, Icon, Select } from 'antd';
import { getGaToSubscription, postGaToSubscription } from '../../axios';

const { Option } = Select;

const itemvalueMap = [
    {
        label: 'enable',
        value: '1'
    },
    {
        label: 'disable',
        value: '2'
    }
]

const operationTitleArr = ['Add Enterprise','Edit Enterprise']
const operationMap = {
    add:0,
    edit:1
}

@withTranslation(['common'])
class ExistingLicense extends React.Component {
    state = {
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true
        },
        data: [],
        visibleAddModal: false,
        operation:operationMap.add,
        enterpriseId: '',
        type: '',
        itemname: '',
    }
    constructor() {
        super()
        this.init();
    }
    init = () => {
        this.getGaToSubscriptionHandler({});
    }
    getGaToSubscriptionHandler = ({ enterpriseId = '', type = '', itemname = '', page = 0, pageSize = 10 }) => {
        getGaToSubscription({ enterpriseId, type, itemname, page, pageSize }).then(res => {
            const { code, data: listData, msg } = res;
            if (code === 100000) {
                const { total, data } = listData;
                let { pagination } = this.state;
                pagination.total = total
                this.setState({
                    pagination,
                    data
                })
            } else {
                message.error(msg)
            }
        })
    }
    edit = (record) => {
        this.props.form.setFields({
            enterpriseId: {
                value: record.enterpriseId,
            },
            itemvalue: {
                value: record.itemvalue,
            },
        });
        this.setState({ visibleAddModal: true,operation:operationMap.edit })
    }
    search = () => {
        const { enterpriseId, type, itemname, pagination } = this.state;
        this.getGaToSubscriptionHandler({
            enterpriseId, type, itemname,
            pageSize: pagination.pageSize,
            page: pagination.current
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                pageSize,
                current
            }
        }, () => {
            this.search()
        })
    }
    add = () => {
        this.setState({ visibleAddModal: true,operation:operationMap.add })
    }
    close = () => {
        this.props.form.resetFields();
        this.setState({ visibleAddModal: false })
    }
    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (err) { return }
            postGaToSubscription({
                ...values
            }).then(res => {
                const { code, data: listData, msg } = res;
                if (code === 100000) {
                    this.getGaToSubscriptionHandler({});
                    this.close();
                    message.success(msg)
                } else {
                    message.error(msg)
                }
            })
        });
    }
    render() {
        const columns = [
            {
                title: 'enterpriseId',
                dataIndex: 'enterpriseId',
                key: 'enterpriseId',
                render: text => <a>{text}</a>,
            },
            {
                title: 'created',
                dataIndex: 'created',
                key: 'created',
            },
            {
                title: 'itemvalue',
                dataIndex: 'itemvalue',
                key: 'itemvalue',
                render: (text, record) => (
                    <span>{itemvalueMap.find(item => item.value === text).label}</span>
                ),
            },
            {
                title: 'itemname',
                dataIndex: 'itemname',
                key: 'itemname',
            },
            {
                title: 'type',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.edit(record)}><Icon type="edit" /></a>
                    </span>
                ),
            },
        ];
        const t = this.props.t;
        const { getFieldDecorator } = this.props.form;
        const { pagination, data, visibleAddModal, operation } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
        };
        return (
            <div className="main-new">
                <div className="main-cotent">
                    <div className="cotent cotent-wrap" style={{ padding: '10px' }}>
                        <div className='searchWrap' style={{ display: 'inline-block' }}>
                            <Input style={{ width: 200, marginRight:'10px' }} placeholder="EnterpriseId" onChange={e => this.setState({ enterpriseId: e.target.value })} />
                            <Select style={{ width: 120, marginRight:'10px' }} placeholder="type" onChange={(value) => this.setState({ type: value })}>
                                {itemvalueMap.map((item, index) =>
                                    <Option key={index} value={item.value}>{item.label}</Option>
                                )}
                            </Select>
                            <Input style={{ width: 200, marginRight:'10px' }} placeholder="itemname" onChange={e => this.setState({ itemname: e.target.value })} />
                            <Button onClick={this.search}>Search</Button>
                        </div>
                        <Button style={{ float: 'right', marginRight: '10px' }} onClick={this.add}>
                            Add
                        </Button>
                        <Table
                            style={{ marginTop: '20px' }}
                            columns={columns}
                            pagination={{ ...pagination, onShowSizeChange: this.onShowSizeChange }}
                            dataSource={data.length > 0 ? data : null}
                        />
                    </div>
                </div>
                <Modal title={t(operationTitleArr[operation])}
                    visible={visibleAddModal}
                    onCancel={this.close}
                    onOk={this.handleOk}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item label="enterpriseId">
                            {getFieldDecorator("enterpriseId", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your enterpriseId!",
                                    }
                                ],
                            })(<Input  disabled={operation === operationMap.edit}/>)}
                        </Form.Item>
                        <Form.Item label="itemvalue">
                            {getFieldDecorator("itemvalue", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your itemvalue!",
                                    }
                                ],
                            })(<Radio.Group>
                                {itemvalueMap.map((item, index) =>
                                    <Radio key={index} value={item.value}>{item.label}</Radio>
                                )}
                            </Radio.Group>)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default connectAlita(["serverConfig"])(Form.create({ name: "licenseInfo" })(ExistingLicense));