
import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import AllComponents from '../components';
import routesConfig from './config';
import queryString from 'query-string';
import { getLicenseKeyfile } from '../axios';
import axios from 'axios';
import { connectAlita } from 'redux-alita';
import { withTranslation } from 'react-i18next';
import cookie from 'react-cookies'
@withTranslation(['common'])
class CRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.ajaxSetup();
    }

    componentWillMount() {

    }



    ajaxSetup = () => {
        axios.interceptors.response.use(
            response => {
                if (response.data.code == 100012) {//登录过期
                    console.log('登录过期: ', response.data);
                    localStorage.removeItem('user');
                    return <Redirect to={'/'} />;
                }
                return response;
            },
            error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401: 401
                    }
                }
                return Promise.reject(error.response ? error.response.data : error)   // 返回接口返回的错误信息
            })
    }

    requireLogin = (component, permission, r) => {
        const { auth, } = this.props;
        const data = auth.data;
        if (!data) {
            localStorage.removeItem('user');
            return <Redirect to={'/'} />;
        } 
        return permission ? "" : component;
    };

    render() {
        // console.log("CRouter alitaState:", this.props.auth.data);
        return (
            <Switch>
                {
                    Object.keys(routesConfig).map(key =>
                        routesConfig[key].map(r => {
                            const route = r => {
                                const Component = AllComponents[r.component];
                                return (
                                    <Route
                                        key={r.route || r.key}
                                        exact
                                        path={r.route || r.key}
                                        render={props => {
                                            const reg = /\?\S*/g;
                                            // 匹配?及其以后字符串
                                            const queryParams = window.location.hash.match(reg);
                                            // 去除?的参数
                                            const { params } = props.match;
                                            Object.keys(params).forEach(key => {
                                                params[key] = params[key] && params[key].replace(reg, '');
                                            });
                                            props.match.params = { ...params };
                                            const merge = { ...props, query: queryParams ? queryString.parse(queryParams[0]) : {} };
                                            // 重新包装组件
                                            const wrappedComponent = (
                                                <DocumentTitle title={this.props.t(r.title)}>
                                                    <Component {...merge} />
                                                </DocumentTitle>
                                            )
                                            // return wrappedComponent
                                            return r.login
                                                ? wrappedComponent
                                                : this.requireLogin(wrappedComponent, r.auth, r)
                                        }}
                                    />

                                )
                            }
                            return r.component ? route(r) : r.subs.map(r => route(r));
                        })
                    )
                }

                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        )
    }
}
export default withRouter(connectAlita(['auth', 'serverConfig', 'title'])(CRouter));
