/**
 * 左边菜单组件
 */
import React, { Component } from 'react';
import { Layout, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import routes from '../routes/config';
import SiderMenu from './SiderMenu';
import { connectAlita } from 'redux-alita';
import { getLicenseMode } from '../axios';
const { Sider } = Layout;
import { withTranslation, Trans } from 'react-i18next';
import logo from '../style/imgs/logo.svg'

@withTranslation(['common'])
class SiderCustom extends Component {
    static getDerivedStateFromProps(props, state) {
        if (props.location.pathname != state.selectedKey) {
            // console.info(props.location, state.selectedKey)
            const { pathname } = props.location;
            state.selectedKey = props.location.pathname;
            state.openKey = pathname.substr(0, pathname.lastIndexOf('/'));
        }
        if (props.collapsed !== state.collapsed) {
            const state1 = SiderCustom.setMenuOpen(props);
            const state2 = SiderCustom.onCollapse(props.collapsed);
            return {
                ...state1,
                ...state2,
                firstHide: state.collapsed !== props.collapsed && props.collapsed, // 两个不等时赋值props属性值否则为false
                openKey: state.openKey || (!props.collapsed && state1.openKey)
            }
        }
        return null;
    }
    static setMenuOpen = props => {
        const { pathname } = props.location;
        // console.info("setMenuOpen", pathname)
        return {
            openKey: pathname.substr(0, pathname.lastIndexOf('/')),
            selectedKey: pathname
        };
    };
    static onCollapse = (collapsed) => {
        return {
            collapsed,
            // firstHide: collapsed,
            mode: collapsed ? 'vertical' : 'inline',
        };
    };
    state = {
        mode: 'inline',
        openKey: '',
        selectedKey: '',
        firstHide: true, // 点击收缩菜单，第一次隐藏展开子菜单，openMenu时恢复
        licenseMode: 1
    };
    componentDidMount() {
        const state = SiderCustom.setMenuOpen(this.props);
        this.setState(state);
        window.addEventListener("popstate", this.reload)
    }
    componentWillUnmount() {
        window.removeEventListener("popstate", this.reload)
    }

    reload = () => {
        // const state = SiderCustom.setMenuOpen(this.props);
        // this.setState(state);
        window.location.reload();
    }

    menuClick = e => {
        this.setState({
            selectedKey: e.key
        });
        const { popoverHide } = this.props; // 响应式布局控制小屏幕点击菜单时隐藏菜单操作
        popoverHide && popoverHide();
    };
    openMenu = v => {
        this.setState({
            openKey: v[v.length - 1],
            firstHide: false,
        })
    };

    render() {
        const t = this.props.t;
        const { selectedKey, openKey, firstHide, licenseMode } = this.state;
        const { modules = { data: {} }, serverConfig, history, userWizard = { data: {} }, responsive = { data: {} }, userIDMode = { data: {} }, licenseRestriction = { data: {} }, showWizard, setAlitaState, clientHeight = { data: 0 } } = this.props;
        return (
            <Sider
                trigger={null}
                breakpoint="lg"
                collapsed={this.props.collapsed}
                // width={this.props.collapsed?56:290}
                style={{
                    overflow: 'auto',
                    zIndex: 10,
                    height: '100%',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                }}
                className={this.props.collapsed ? "sider-wrap meunTest new-width-56" : "sider-wrap meunTest new-width-290"}
            >
                <div className={this.props.collapsed ? 'display-flex space-between align-bottom align-center logo-wrap new-logo new-meun-close' : 'display-flex space-between align-bottom align-center logo-wrap new-logo new-meun-open'} >
                    <div hidden={this.props.collapsed}>
                        {/* <i className="icon-logo" style={{ fontSize: '24px' }}></i> */}
                        <img src={logo} alt="logo" className='logoimg' style={{ width: '75%' }} />
                    </div>

                    < div className={this.props.collapsed ? 'openMenuBtn' : ''}>
                        <i
                            className="icon-list header__trigger custom-trigger"
                            // type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.props.toggle}
                            style={{ fontSize: '16px' }}
                        />
                    </div>

                </div>
                <div style={{ marginTop: '74px' }}>
                    <SiderMenu
                        menus={routes.menus}
                        onClick={this.menuClick}
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        openKeys={firstHide ? null : [openKey]}
                        onOpenChange={this.openMenu}
                        modules={modules}
                        serverConfig={serverConfig}
                        userWizard={userWizard}
                        showWizard={showWizard}
                        userIDMode={userIDMode}
                        licenseRestriction={licenseRestriction}
                        t={t}
                        push={history.push}
                        setAlitaState={setAlitaState}
                        collapsed={this.props.collapsed ? 0 : 1}
                        clientHeight={clientHeight}
                        licenseMode={licenseMode}
                    />
                </div>
                {/* <div>
                    <Icon
                        className={this.props.collapsed ? 'header__trigger custom-trigger-new pd-left-32' : 'header__trigger custom-trigger-new pd-left-20'}
                        type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={this.props.toggle}
                    />
                </div> */}
                <style>
                    {`
                    #nprogress .spinner{
                        left: ${this.props.collapsed ? '70px' : '172px'};
                        right: 0 !important;
                    }
                    `}
                    {`
                     .wrap_bottom{
                        left: ${this.props.collapsed ? '71px' : '272px'};
                    }
                    `}
                    {`
                     .backListBtn{
                        left: ${this.props.collapsed ? '71px' : '272px'};
                    }
                    `}
                </style>
            </Sider>
        )
    }
}

export default withRouter(connectAlita(['modules', 'serverConfig', 'userWizard', 'showWizard', 'responsive', 'userIDMode', 'licenseRestriction', 'clientHeight'])(SiderCustom));
