
import { get, post, getList, put, DELETE } from './tools';
import * as config from './config';

// 获取服务器配置
export const getServerConfig = () => get({ url: config.GET_SERVER_CONFIG });

// 注册
export const signUp = data => post({
    url: config.SIGNUP,
    data: {
        email: data.email,
        password: data.password
    },
});

// 登录
export const login = data => post({
    url: config.LOGIN,
    data: {
        email: data.email,
        password: data.password,
        remember: data.remember
    },
});

// ADMIN_USER_INFO 获取用户信息
export const getUserInfo = () => get({ url: config.ADMIN_USER_INFO });

// 登出
export const logout = (data) => get({
    url: config.LOGOUT,
    // params: data
});

// 列表接口
export const getTenantsData = (data) => getList({
    url: config.GET_TENANTS_DATA,
    params: data
});

// SITE_RELEASE_LOG
export const getUpdateLog = (data) => getList({
    url: config.SITE_RELEASE_LOG,
    params: data
});

export const postUpdateLog = data => post({
    url: config.SITE_RELEASE_LOG,
    data: data
});

export const delUpdateLog = data => put({
    url: config.SITE_RELEASE_LOG,
    data: data
});

export const getDeleteUserList = (data) => getList({
    url: config.SITE_DELETE_USER,
    params: data
});

export const postDeleteUser = data => post({
    url: config.SITE_DELETE_USER,
    data: data
});

// GET_PUT_SITE_CONFIG_SUPPORT
export const getSiteConfigSupport = (data) => get({
    url: config.GET_PUT_SITE_CONFIG_SUPPORT,
    params: data
});

// GET_PUT_SITE_CONFIG_SUPPORT
export const putSiteConfigSupport = data => put({
    url: config.GET_PUT_SITE_CONFIG_SUPPORT,
    data: data
});

// GET_GA_TO_SUBSCRIPTION
export const getGaToSubscription = (data) => get({
    url: config.GA_TO_SUBSCRIPTION,
    params: data
});

// POST_GA_TO_SUBSCRIPTION
export const postGaToSubscription = data => post({
    url: config.GA_TO_SUBSCRIPTION,
    data
});