/**
 * key:路由
 * title:菜单名称/浏览器title
 * icon:菜单icon
 * component:菜单对应组件
 * licenseMode:账号模式/非账号模式
 * mode:ldap/saml/skip
 * initialization:向导顺序
 * type:多租户/单租户
 * allow:针对特殊部署不显示internal update 界面CPDFI-2764
 * os:win/docker
 * appType:是否为ius部署环境
 * role:用户角色
 */
export default {
    menus: [ // 菜单相关路由
        {
            key: '/updatelog', title: 'UpdateLog', icon: 'icon-settings', component: 'UpdateLog'
        },
        {
            key: '/tenantsdata', title: 'TenantsData', icon: 'icon-reports', component: 'TenantsData'
        },
        {
            key: '/failed', title: 'Failed To Delete Users', icon: 'icon-user-id-new', component: 'DeleteUsersList'
        },
        {
            key: '/contact', title: 'Update Contact', icon: 'icon-edit', component: 'UpdateContactInfo'
        },
        {
            key: '/existLicenses', title: 'Taking over existing licenses', icon: 'icon-list', component: 'ExistingLicense'
        }
    ],
    others: [] // 非菜单相关路由
}
