import React from 'react';
import { withTranslation } from 'react-i18next';
import { connectAlita } from 'redux-alita';
import { Icon, message, Table, Button } from 'antd';
import dataSvg from '../../style/imgs/no_data.svg';
import EditorComponent from './EditorComponent'
import { getUpdateLog, delUpdateLog } from '../../axios';
@withTranslation(['common'])
class UpdateLog extends React.Component {
    constructor(props) {
        super(props);
        const t = this.props.t
        this.state = {
            data: [],
            loading: false,
            isShowEditModal: false,
            page: 1,
            per_page: 10,
            per_total: 0,
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        const { page, per_page } = this.state
        var data = { page: page, pageSize: per_page }
        getUpdateLog(data).then((res) => {
            if (res.code === 100000) {
                this.setState({
                    data: res.data ? res.data : [],
                    page: res.headers.page,
                    per_total: res.headers.per_total
                })
            }
        })
    }

    pageChange = (page, pageSize) => {
        this.setState({
            page: page
        },()=>{
            this.init()
        })
    }

    delete = (record) => {
        delUpdateLog({ logId: record.id }).then(res => {
            if (res.code === 100000) {
                message.success(res.msg)
                this.init()
            } else {
                message.warn(res.msg)
            }
        })
    }

    render() {
        const { data, page, per_page, per_total, loading, isShowEditModal } = this.state
        const t = this.props.t
        let columns = [
            {
                title: <span>{t('Date')}</span>,
                dataIndex: 'log_date',
            },
            {
                title: <span>{t('language')}</span>,
                dataIndex: 'al',
                render: (text, record) => (
                    <span>
                        {text === 'en-US' ? '英文' : '中文'}
                    </span>
                )
            },
            {
                title: <span>{t('New features')}</span>,
                dataIndex: 'features',
                render: (text, record) => (
                    <span dangerouslySetInnerHTML={{ __html: text }}>
                    </span>
                )
            },
            {
                title: <span>{t('Resolved issues')}</span>,
                dataIndex: 'issues',
                render: (text, record) => (
                    <span dangerouslySetInnerHTML={{ __html: text }}>
                    </span>
                )
            },
            {
                title: t('Operations'),
                render: (text, record) => (
                    <span className='invited-action'>
                        <a onClick={() => { this.delete(record) }}><Icon type="delete" />删除</a>
                    </span>
                )
            }
        ]
        return (
            <div className="main-new">
                <div className="main-cotent">
                    <div className="cotent cotent-wrap" style={{ padding: '10px' }}>
                        <Button onClick={() => { this.setState({ isShowEditModal: true }) }}>
                            添加日志
                        </Button>
                        <div className="table-wrap-new pd-top-10">
                            <Table
                                rowKey={record => record.id}
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                pagination={{ current: page, pageSize: per_page, total: per_total, onChange: this.pageChange }}
                                locale={{
                                    emptyText:
                                        <div className='empty-table'>
                                            <img src={dataSvg}></img>
                                            <p> {t('NoData')}</p>
                                        </div>
                                }}
                                scroll={{ x: '100%' }} />
                        </div>
                    </div>
                </div>
                {isShowEditModal && <EditorComponent
                    visible={isShowEditModal}
                    close={(type) => {
                        this.setState({ isShowEditModal: false });
                        if (type) {
                            this.init()
                        }
                    }}
                />}
            </div>
        )
    }
}

export default connectAlita(['serverConfig'])(UpdateLog)
