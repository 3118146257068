import React from 'react';
import { withTranslation } from 'react-i18next';
import { connectAlita } from 'redux-alita';
import { Icon, Form, Dropdown, Table, Row, Col, Input, Button, Spin, message, Select, DatePicker, Menu, Checkbox } from 'antd';
import { getTenantsData } from '../../axios';
import moment from 'moment';
import { convertUTCDateToLocalDate } from '../../utils'
import $ from 'jquery'
import dataSvg from '../../style/imgs/no_data.svg';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
import jstz from 'jstz'
@withTranslation(['common'])
class TenantsData extends React.Component {
    constructor(props) {
        super(props);
        const t = this.props.t
        this.state = {
            tipsShow: false,
            page: 1,              //当前页
            per_page: 10,         //每页数量
            per_total: 0,         //总数
            data: [],
            purchasedStartTime: null,
            purchasedEndTime: null,
            expiredStartTime: null,
            expiredEndTime: null,
            searchShow: false,
            visible: false,
            options: [
                { label: t('WindowsPhantomPDFStandard'), value: 1 },
                { label: t('WindowsPhantomPDFBusiness'), value: 2 },
                { label: t('FoxitSignUserModel'), value: 3 },
                { label: t('FoxitSignEnvelopeModel'), value: 4 },
                { label: t('MacPhantomPDF'), value: 5 },
                { label: t('PhantomPDFOnline'), value: 6 }
            ],
            checkedList: [],
            indeterminate: false,
            checkAll: false,
            optionsValue: [1, 2, 3, 4, 5, 6],
            companyName: null,
            loading: false,
            numShow: false,
            sortBy: { "purchase_date": "ASC", "expire_date": "ASC" },
            searchPlaceholder: '',
            product: [],
            clearShow: false
        }
    }

    componentDidMount() {
        var data = { page: 1, pageSize: this.state.per_page }
        this.init(data);
    }

    init = (data) => {
        this.setState({
            loading: true
        })
        getTenantsData(data).then(res => {
            if (res.code === 100000) {
                // console.log("TenantsData -> init -> res.code", res)
                this.setState({
                    loading: false,
                    data: res.data,
                    page: res.headers.page,
                    per_total: res.headers.per_total
                })
            } else {
                console.log('+++++++列表接口出错+++++++')
            }
        })
        if (data.companyName || data.$purchasedStartTime || data.$expiredStartTime) {
            this.setState({
                numShow: true
            })
        } else {
            this.setState({
                numShow: false
            })
        }
    }

    onPurchasedTimeChange = (date, dateString) => {
        console.log(date);
        if (date.length != 0 && date[0] != '' && date[1] != '') {
            this.setState({
                purchasedStartTime: new Date(date[0]).toISOString(),
                purchasedEndTime: new Date(date[1]).toISOString()
            })
        } else {

        }
    }

    onExpiredTimeChange = (date, dateString) => {
        console.log(date);
        if (date.length != 0 && date[0] != '' && date[1] != '') {
            this.setState({
                expiredStartTime: new Date(date[0]).toISOString(),
                expiredEndTime: new Date(date[1]).toISOString()
            })
        } else {

        }
    }


    handleSearch = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values,);
            const { companyName, per_page, sortBy, page, product } = this.state
            const t = this.props.t
            var se = ''
            if (companyName && companyName != '') {
                se = se + t('CompanyName') + ' '
            }
            var data = { purchasedStartTime: null, purchasedEndTime: null, expiredStartTime: null, expiredEndTime: null, page: page, pageSize: per_page, sortBy: sortBy, companyName: companyName, products: JSON.stringify(product) }
            if (values.PurchasedTime && values.PurchasedTime.length != 0 && values.PurchasedTime[0] != '' && values.PurchasedTime[1] != '') {
                data.purchasedStartTime = new Date(values.PurchasedTime[0]).toISOString();
                data.purchasedEndTime = new Date(values.PurchasedTime[1]).toISOString();
                se = se + t('PurchasedTime') + ' '
            }
            if (values.ExpiredTime && values.ExpiredTime.length != 0 && values.ExpiredTime[0] != '' && values.ExpiredTime[1] != '') {
                data.expiredStartTime = new Date(values.ExpiredTime[0]).toISOString();
                data.expiredEndTime = new Date(values.ExpiredTime[1]).toISOString();
                se = se + t('ExpiredTime') + ' '
            }
            this.init(data)
            this.setState({
                searchShow: false,
                numShow: true,
                searchPlaceholder: se,
                clearShow: (se == '' || this.state.companyName) ? false : true
            })
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
            searchShow: false,
            numShow: false,
            companyName: null,
            searchPlaceholder: '',
            product: [],
            clearShow: false,
        })
        var data = { page: 1, pageSize: this.state.per_page }
        this.init(data)
    };

    // 分页切换
    pageChange = (page, pageSize) => {
        this.setState({
            page: page
        })
        const { companyName, per_page, sortBy, purchasedStartTime, purchasedEndTime, expiredStartTime, expiredEndTime } = this.state
        var data = { page: page, pageSize: per_page, sortBy: sortBy, companyName: companyName, purchasedStartTime: purchasedStartTime, purchasedEndTime: purchasedEndTime, expiredStartTime: expiredStartTime, expiredEndTime: expiredEndTime }
        this.init(data)
    }

    // 排序
    Sort = (sort, type) => {
        var sortBy = { "purchase_date": "ASC", "expire_date": "ASC" };
        if (type == 1) {
            sortBy.purchase_date = sort
        }
        if (type == 2) {
            sortBy.expire_date = sort
        }
        this.setState({
            sortBy: sortBy
        })
        const { page, companyName, per_page, purchasedStartTime, purchasedEndTime, expiredStartTime, expiredEndTime, product } = this.state
        var data = { sortBy: sortBy, page: page, pageSize: per_page, companyName: companyName, purchasedStartTime: purchasedStartTime, purchasedEndTime: purchasedEndTime, expiredStartTime: expiredStartTime, expiredEndTime: expiredEndTime, products: JSON.stringify(product) }
        this.init(data);
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            checkedList: [],
            indeterminate: false,
            checkAll: false,
            product: []
        });
        const { companyName, per_page, sortBy, page, purchasedStartTime, purchasedEndTime, expiredStartTime, expiredEndTime } = this.state
        var data = { page: page, pageSize: per_page, sortBy: sortBy, companyName: companyName, purchasedStartTime: purchasedStartTime, purchasedEndTime: purchasedEndTime, expiredStartTime: expiredStartTime, expiredEndTime: expiredEndTime }
        this.init(data)
    };

    ArrayToHeavy =(arr) =>{
        return arr.filter((item, index)=> {
            return arr.indexOf(item) === index
        })
    }

    handleOK = () => {
        const { checkedList } = this.state
        var product = []
        checkedList.map(item => {
            switch (item) {
                case 1:
                    product.push('phantom_ga_standard_org')
                    break;
                case 2:
                    product.push('phantom_ga_business_org')
                    break;
                case 3:
                    product.push('foxit_sign')
                    break;
                case 4:
                    product.push('foxit_sign')
                    break;
                case 5:
                    product.push('phantom_mac_org')
                    break;
                case 6:
                    product.push('phantom_ga_online_org')
                    break;
            }
        })
        product = this.ArrayToHeavy(product)
        const { companyName, per_page, sortBy, page, purchasedStartTime, purchasedEndTime, expiredStartTime, expiredEndTime } = this.state
        var data = { page: page, pageSize: per_page, sortBy: sortBy, companyName: companyName, purchasedStartTime: purchasedStartTime, purchasedEndTime: purchasedEndTime, expiredStartTime: expiredStartTime, expiredEndTime: expiredEndTime, products: JSON.stringify(product) }
        this.init(data)
        this.setState({
            visible: false,
            product: [...product]
        });

    };

    handleVisibleChange = flag => {
        this.setState({ visible: flag });
    };

    onChange = checkedList => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < this.state.options.length,
            checkAll: checkedList.length === this.state.options.length,
        });
    };

    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? this.state.optionsValue : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    onPressEnter = (e) => {
        const { sortBy, per_page, purchasedStartTime, purchasedEndTime, expiredStartTime, expiredEndTime, product } = this.state
        var data = { page: 1, pageSize: per_page, companyName: e.target.value, sortBy: sortBy, purchasedStartTime: purchasedStartTime, purchasedEndTime: purchasedEndTime, expiredStartTime: expiredStartTime, expiredEndTime: expiredEndTime, products: JSON.stringify(product) }
        this.init(data);
    }

    onInputChange = (e) => {
        this.setState({
            companyName: e.target.value,
            clearShow: false,
        })
        if (!e.target.value) {
            this.handleReset()
        }
    }

    All = () => {
        this.handleReset()
    }

    getProduct = (record) => {
        var list = []
        const t = this.props.t
        if (record.attached_app_code && record.attached_app_code.length > 0) {
            record.attached_app_code.map((item) => {
                switch (item.app_code) {
                    case 'phantom_ga_standard_org':
                        list.push(<span key={item.app_code}>{t('WindowsPhantomPDFStandard')} ({typeof (record.standardUsed) != 'undefined' ? record.standardUsed : '--'}/{item.count})</span>);
                        break;
                    case 'phantom_ga_business_org':
                        list.push(<span key={item.app_code}>{t('WindowsPhantomPDFBusiness')} ({typeof (record.businessUsed) != 'undefined' ? record.businessUsed : '--'}/{item.count})</span>);
                        break;
                    case 'phantom_mac_org':
                        list.push(<span key={item.app_code}>{t('MacPhantomPDF')} ({typeof (record.macUsed) != 'undefined' ? record.macUsed : '--'}/{item.count})</span>);
                        break;
                    case 'phantom_ga_online_org':
                        list.push(<span key={item.app_code}>{t('PhantomPDFOnline')} ({typeof (record.onlineUsed) != 'undefined' ? record.onlineUsed : '--'}/{item.count})</span>);
                        break;
                    case 'foxit_sign':
                        item.info.foxit_sign_user_numbers && list.push(<span key={item.app_code + '1'}>{t('FoxitSignUserModel')} ({typeof (record.signUserUsed) != 'undefined' ? record.signUserUsed : '--'}/{item.info.foxit_sign_user_numbers})</span>);
                        item.info.foxit_sign_envelope_numbers && list.push(<span key={item.app_code + '2'}>{t('FoxitSignEnvelopeModel')} ({typeof (record.signEnvelopeUserUsed) != 'undefined' ? record.signEnvelopeUserUsed : '--'}/{item.info.foxit_sign_envelope_numbers})</span>);
                        break;
                }
            })
        } else {
            list.push(<span key={record.email}>--</span>)
        }
        return list
    }

    render() {
        const t = this.props.t;
        const { getFieldDecorator } = this.props.form;
        const { searchPlaceholder, clearShow, loading, page, per_page, per_total, data, sortBy, searchShow, options, visible, checkedList, indeterminate, checkAll, companyName, numShow } = this.state;
        // console.log("TenantsData -> render -> checkedList", checkedList)
        const list = []
        const menu = (
            <Menu onClick={this.handleMenuClick} style={{ top: '12px' }}>
                <div className="menu-warap">
                    <div className="menu-content pa-s pa-m">
                        <Checkbox
                            indeterminate={indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={checkAll}
                        >
                            {t('All Products')}
                        </Checkbox>
                        <Checkbox.Group
                            onChange={this.onChange}
                            value={checkedList}
                            options={options}
                        />
                    </div>
                    <div className="menu-footer text-right" style={{ borderTop: '1px solid #e5e5e5', padding: '0.3rem 0.8rem' }} >
                        <Button className="ant-btn-transparent" onClick={this.handleCancel}>
                            {t('Cancel')}
                        </Button>
                        <Button key="OK" className="ant-btn-dominant" style={{ height: '28px', minWidth: '0px' }}
                            onClick={this.handleOK}>
                            {t('OK')}
                        </Button>
                    </div>
                </div>
            </Menu>
        );

        let columns = [
            {
                title: <span>{t('CompanyName')}</span>,
                dataIndex: 'organization',
            },
            {
                title: <span>{t('Admin')}</span>,
                dataIndex: 'email',
            },
            {
                title: <span className='min-width-time'>{t('PurchasedTime')}
                    <span className="icon-order-down" style={{ fontSize: '16px', padding: '0 10px', display: sortBy.purchase_date === 'DESC' ? 'inline-block' : 'none' }} onClick={this.Sort.bind(this, 'ASC', 1)}><span className="path1"></span><span className="path2"></span></span>
                    <span className="icon-order-up" style={{ fontSize: '16px', padding: '0 10px', display: sortBy.purchase_date !== 'DESC' ? 'inline-block' : 'none' }} onClick={this.Sort.bind(this, 'DESC', 1)}><span className="path1"></span><span className="path2"></span></span>
                </span>,
                dataIndex: 'create_date',
                render: (dataIndex) => {
                    return convertUTCDateToLocalDate(dataIndex);
                }
            },
            {
                title: <span className='min-width-time'>{t('ExpiredTime')}
                    <span className="icon-order-down" style={{ fontSize: '16px', padding: '0 10px', display: sortBy.expire_date === 'DESC' ? 'inline-block' : 'none' }} onClick={this.Sort.bind(this, 'ASC', 2)}><span className="path1"></span><span className="path2"></span></span>
                    <span className="icon-order-up" style={{ fontSize: '16px', padding: '0 10px', display: sortBy.expire_date !== 'DESC' ? 'inline-block' : 'none' }} onClick={this.Sort.bind(this, 'DESC', 2)}><span className="path1"></span><span className="path2"></span></span>
                </span>,
                dataIndex: 'expire_date',
                render: (dataIndex) => {
                    return dataIndex ? <span>{convertUTCDateToLocalDate(dataIndex)}</span> : <span>{t('permanently effective')}</span>
                }
            },
            {
                title: <span>
                    <span>{t('PurchasedProducts')}</span>
                    <Dropdown
                        overlay={menu}
                        onVisibleChange={this.handleVisibleChange}
                        visible={visible}
                        trigger={['click']}
                        getPopupContainer={triggerNode => triggerNode}
                        placement="bottomRight"
                    >
                        <Icon type="down" style={{ padding: '0 10px' }}></Icon>
                    </Dropdown>
                </span>,
                // dataIndex: 'PurchasedProducts',
                render: (record, text) => {
                    return <div className='prdocutList'>
                        {this.getProduct(record)}
                    </div>
                }
            },
        ];

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 9 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
        };
        return (
            <div className="main-new">
                <a href='#' id='download' hidden></a>
                <div className="main-cotent">
                    <div className="cotent cotent-wrap">
                        <div className='pa-m'>
                            <Input
                                style={{ width: '300px' }}
                                className='inputEllipsis'
                                placeholder={searchPlaceholder != '' ? t('SearchFor', { user: searchPlaceholder }) : t('SearchCompanyName')}
                                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={companyName}
                                onPressEnter={this.onPressEnter}
                                onChange={this.onInputChange}
                                allowClear
                                suffix={
                                    clearShow ? <Icon type="close-circle" theme="filled" onClick={() => { this.handleReset() }} style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: '12px' }} /> :
                                        (searchShow ? <Icon type="down-circle" theme="filled" style={{ color: '#F58320', fontSize: '16px' }} onClick={() => { this.setState({ searchShow: false }) }} /> : <Icon type="down" style={{ color: 'rgba(0,0,0,.45)', fontSize: '10px' }} onClick={() => { this.setState({ searchShow: true }) }} />)
                                }
                            />
                            <div className='TotalCompany'>
                                {!numShow ?
                                    t('TotalCompany', { num: per_total }) :
                                    <span><span style={{ color: '#f58320', cursor: 'pointer' }} onClick={() => { this.All() }}>{t('All')}</span>&nbsp;{'>'}&nbsp;{t('Searchresult', { num: per_total })}</span>
                                }
                            </div>

                            <div className='plpr-10 ptpb-10 newSearchBox' style={{ display: searchShow ? "" : "none" }}>
                                <div style={{ fontWeight: '550' }}>
                                    {t('AdvancedSearch')}
                                </div>
                                <div style={{ padding: '20px' }}>
                                    <Form className="ant-advanced-search-form" onSubmit={this.handleSearch} {...formItemLayout}>
                                        <Row gutter={20} className="h-32 d-f ai-c">
                                            <Col span={24} className="d-f ai-c">
                                                {/* <div className="fs-0 pr-10 c-333 fs-12">{t('PurchasedTime')}</div> */}
                                                <Form.Item className="f-1" label={t('PurchasedTime')}>
                                                    {getFieldDecorator('PurchasedTime', {
                                                        // initialValue: [moment().startOf('day'), moment().endOf('day')],
                                                    })(<RangePicker onChange={this.onPurchasedTimeChange} format={dateFormat} allowClear={false} placeholder={[t('StartTime'), t('EndTime')]} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20} className="h-32 d-f ai-c">
                                            <Col span={24} className="d-f ai-c">
                                                {/* <div className="fs-0 pr-10 c-333 fs-12">{t('ExpiredTime')}</div> */}
                                                <Form.Item className="f-1" label={t('ExpiredTime')}>
                                                    {getFieldDecorator('ExpiredTime', {
                                                        // initialValue: [moment().startOf('day'), moment().endOf('day')],
                                                    })(<RangePicker onChange={this.onExpiredTimeChange} format={dateFormat} allowClear={false} placeholder={[t('StartTime'), t('EndTime')]} />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20} className="mt-10 h-32 d-f ai-c">
                                            <Col span={18} offset={6} className="d-f ai-c jc-fe">
                                                <Button className="ant-btn-link" onClick={this.handleReset} style={{ height: '32px' }}>{t('Cancel')}</Button>
                                                <Button className="ant-btn-dominant button-wh-search" id="mainSearch" htmlType="submit" style={{ height: '32px' }}>{t('Search')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="table-wrap-new pd-top-10">
                            <Table
                                rowKey={record => record.email}
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                pagination={{ current: page, pageSize: per_page, total: per_total, onChange: this.pageChange }}
                                locale={{
                                    emptyText:
                                        <div className='empty-table'>
                                            <img src={dataSvg}></img>
                                            <p> {t('NoData')}</p>
                                        </div>
                                }}
                                scroll={{ x: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connectAlita(['serverConfig'])(Form.create({ name: 'AdminOperationLogs_search' })(TenantsData))
