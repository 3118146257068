/**
 * 接口地址配置文件
 */

/**获取所有接口的配置 URL*/
var cpdfadminURI = window.location.protocol + '//' + window.location.host
if (cpdfadminURI.indexOf('http://localhost') !== -1) {
    cpdfadminURI = 'https://pheeadmin-docker.connectedpdf.com'
}

// 接口
export const GET_SERVER_CONFIG = cpdfadminURI + '/api/server-config';
export const SIGNUP = cpdfadminURI + '/api/site/sign-up';
export const SIGNIN = cpdfadminURI + '/api/site/signin';
export const LOGIN = cpdfadminURI + '/api/site/login';
export const ADMIN_USER_INFO = cpdfadminURI + '/api/admin/user-info';
export const LOGOUT = cpdfadminURI + '/api/site/logout';
export const GET_TENANTS_DATA = cpdfadminURI + '/api/site/tenants-data';
export const SITE_RELEASE_LOG = cpdfadminURI + '/api/site/release-log';
export const SITE_DELETE_USER = cpdfadminURI + '/api/site/delete-user';
export const GET_PUT_SITE_CONFIG_SUPPORT = cpdfadminURI + '/api/site-config/support';
export const GA_TO_SUBSCRIPTION = cpdfadminURI + '/api/site-config/ga-to-subscription';










