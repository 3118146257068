import React from "react";
import { withTranslation } from "react-i18next";
import { connectAlita } from "redux-alita";
import { Form, Input, Button, message } from "antd";
import { getSiteConfigSupport, putSiteConfigSupport } from '../../axios';

@withTranslation(["common"])
class UpdateContactInfo extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                if (values.sales || values.phone || values.email) {
                    putSiteConfigSupport(values).then(res => {
                        if (res.code === 100000) {
                            message.success(res.msg)
                        } else {
                            message.warn(res.msg)
                        }
                    })
                } else {
                    message.warn('Cannot be all empty')
                }
            }
        });
    };

    componentDidMount() {
        this.getSiteConfigSupport()
    }

    getSiteConfigSupport = () => {
        getSiteConfigSupport().then(res => {
            if (res.code === 100000 && res.data) {
                this.props.form.setFieldsValue({
                    phone: res.data.phone,
                    sales: res.data.sales,
                    email: res.data.email
                })
            }
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { t } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const validateEmail = (rule, value, callback) => {
            const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if (value && !emailRegex.test(value)) {
                callback('Please enter a valid email address');
            } else {
                callback();
            }
        };

        return (
            <div className="main-new">
                <div className="main-cotent">
                    <div className="cotent cotent-wrap" style={{ padding: "10px" }}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label="Phone">
                                {getFieldDecorator("phone", {
                                    rules: [
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Sales">
                                {getFieldDecorator("sales", {
                                    rules: [
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Email">
                                {getFieldDecorator("email", {
                                    rules: [
                                        [
                                            { required: true, message: 'The input is not valid E-mail!' },
                                            { validator: validateEmail },
                                        ]
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    {t("Submit")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connectAlita(["serverConfig"])(
    Form.create({ name: "contactInfo" })(UpdateContactInfo)
);
