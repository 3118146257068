import React from 'react';
import { withTranslation } from 'react-i18next';
import { connectAlita } from 'redux-alita';
import { Icon, message, Table, Button, Modal } from 'antd';
import dataSvg from '../../style/imgs/no_data.svg';
import { getDeleteUserList, postDeleteUser } from '../../axios';
@withTranslation(['common'])
class DeleteUsersList extends React.Component {
    constructor(props) {
        super(props);
        const t = this.props.t
        this.state = {
            data: [],
            loading: false,
            page: 1,
            per_page: 10,
            per_total: 0,
            visible: false
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        const { page, per_page } = this.state
        var data = { page: page, pageSize: per_page }
        getDeleteUserList(data).then((res) => {
            if (res.code === 100000) {
                this.setState({
                    data: res.data ? res.data : [],
                    page: res.headers.page,
                    per_total: res.headers.per_total
                })
            }
        })
    }

    pageChange = (page, pageSize) => {
        this.setState({
            page: page
        }, () => {
            this.init()
        })
    }

    delete = (record) => {
        postDeleteUser({ email: record.email }).then(res => {
            if (res.code === 100000) {
                message.success(res.msg)
                this.init()
            } else {
                message.warn(res.msg)
            }
        })
    }

    handleOk = () => {
        postDeleteUser().then(res => {
            if (res.code === 100000) {
                message.success(res.msg)
                this.setState({
                    visible: false
                })
                this.init()
            } else {
                this.setState({
                    visible: false
                })
                message.warn(res.msg)
            }
        })
    }

    render() {
        const { data, page, per_page, per_total, loading, visible } = this.state
        const t = this.props.t
        let columns = [
            {
                title: <span>{t('Email')}</span>,
                dataIndex: 'email',
            },
            {
                title: <span>{t('message')}</span>,
                dataIndex: 'message'
            },
            {
                title: <span>{t('createTime')}</span>,
                dataIndex: 'createTime'
            },
            {
                title: <span>{t('updateTime')}</span>,
                dataIndex: 'updateTime'
            },
            {
                title: t('Operations'),
                render: (text, record) => (
                    <span className='invited-action'>
                        <a onClick={() => { this.delete(record) }}><Icon type="delete" />删除</a>
                    </span>
                )
            }
        ]
        return (
            <div className="main-new">
                <div className="main-cotent">
                    <div className="cotent cotent-wrap" style={{ padding: '10px' }}>
                        <Button onClick={() => { this.setState({ visible: true }) }}>
                            全部删除
                        </Button>
                        <div className="table-wrap-new pd-top-10">
                            <Table
                                rowKey={record => record.id}
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                pagination={{ current: page, pageSize: per_page, total: per_total, onChange: this.pageChange }}
                                locale={{
                                    emptyText:
                                        <div className='empty-table'>
                                            <img src={dataSvg}></img>
                                            <p> {t('NoData')}</p>
                                        </div>
                                }}
                                scroll={{ x: '100%' }} />
                        </div>
                    </div>
                </div>
                <Modal
                    title={t("确认删除")}
                    visible={visible}
                    onCancel={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                    onOk={() => {
                        this.handleOk()
                    }}
                    width={630}
                    className="editor-modal"
                    maskClosable={false}
                >
                    <p>确认删除列表上所有用户吗?</p>
                </Modal>
            </div>
        )
    }
}

export default connectAlita(['serverConfig'])(DeleteUsersList)
