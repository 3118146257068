
import React, { Component } from 'react';
import { Menu, Layout, Icon, Popover, Badge, Input, Dropdown } from 'antd';
import { connectAlita } from 'redux-alita';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { logout } from '../axios';
import i18n from "i18next";
import routesConfig from '../routes/config';
import cookie from 'react-cookies'
import { storageGetItem, windowActionFn, storageSetItem } from '../utils/index';
const { Header } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const routeDashboard = routesConfig.menus[0].key;
@withTranslation(['common'])
class HeaderCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            visible: false,
            lang: 'en-US',
            routeArr: [routeDashboard],
            preRoute: null,
            currentRoute: null
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => { return; };
    }

    componentDidMount() {
        this.setState({
            lang: (cookie.load('al') == 'en-US' || cookie.load('al') == 'zh-CN' || cookie.load('al') == 'ko-KR') ? cookie.load('al') : 'en-US'
        })
        i18n.init({
            lng: (cookie.load('al') == 'en-US' || cookie.load('al') == 'zh-CN' || cookie.load('al') == 'ko-KR') ? cookie.load('al') : 'en-US'
        });
        this.setUser();
        let { routeArr } = this.state;
        if (routeArr.length === 1 && this.props.history.location.pathname != routeDashboard) {//刷新页面  从localStorage 取 路由记录
            const local_routeArr_string = localStorage.getItem('routeArr');
            const arr = !!local_routeArr_string ? JSON.parse(local_routeArr_string) : [];
            if (arr[0] === '/' && arr[1] === '/products') {//license 过期
                this.setState({
                    preRoute: '',
                    currentRoute: '',
                })
            } else {
                this.setState({
                    routeArr: arr,
                    preRoute: this.getRouteTitle(arr[0]),
                    currentRoute: this.getRouteTitle(arr[1]),
                })
            }
        }
        this.props.history.listen((route) => {
            let { routeArr } = this.state;
            if (route.pathname === routeArr[routeArr.length - 1]) {//跳转本身路由
                return;
            }
            routeArr.push(route.pathname);
            if (routeArr.length > 2) {
                routeArr.shift()
            }

            if (route.pathname === routeDashboard) {//当前路由 是否 为 'Dashboard'
                this.setState({
                    preRoute: this.getRouteTitle(routeArr[0]),
                    currentRoute: '',
                })
                return
            }

            this.setState({
                preRoute: this.getRouteTitle(routeArr[0]),
                currentRoute: this.getRouteTitle(routeArr[1]),
            })
            localStorage.setItem('routeArr', JSON.stringify(routeArr));
        })
    };

    getRouteTitle(path) {
        let title;
        routesConfig.menus.forEach(item => {
            if (item.key === path) {
                title = item.title
            }
            if (item.hasOwnProperty('subs')) {
                item.subs.forEach(item2 => {
                    if (item2.key === path) {
                        title = item2.title
                    }
                })
            }
        })
        return title;
    }
    goPreRoute = () => {
        const { routeArr } = this.state;
        this.props.history.push(routeArr[0]);
    }
    setUser = () => {
        var _user = this.props.auth ? this.props.auth.data.data : "";
        if (!_user && JSON.parse(localStorage.getItem('user'))) {
            _user = JSON.parse(localStorage.getItem('user')).data
        };
        this.setState({
            user: _user
        });
        // console.log("set user:", this.props.auth, _user);
    }

    menuClick = e => {
        e.key === 'logout' && this.logout();
        e.key === 'zh_CN' && this.changelang(e.key);
        e.key === 'en_US' && this.changelang(e.key);
    };

    logout = () => {
        var data = { userid: this.state.user.userId, email: this.state.user.email }
        logout().then(res => {
            if (res.code == '100000') {
                localStorage.removeItem('user');
                const { setAlitaState } = this.props;
                setAlitaState({ stateName: 'auth', data: null });
                this.props.history.push('/');
            } else {
                console.log('logout onError :', res.data);
            }
        });
    };

    caslogout = () => {
        var url = window.location.origin
        var casApiUrl = storageGetItem(localStorage, 'serverConfig') ? JSON.parse(storageGetItem(localStorage, 'serverConfig')).casServerUrl : ''
        var openUrl = casApiUrl + "/cas/logout?service=" + encodeURIComponent(url)
        windowActionFn(window, 'open', [openUrl, '_self']);
    }

    changelang = (key) => {
        console.log(key)
        this.setState({
            lang: key
        })
        i18n.init({
            lng: key
        });
        var url = window.location.host.split('.')
        var domain = url[1] + '.' + url[2]
        cookie.save('al', key, { path: '/', domain: domain })
        const { setAlitaState } = this.props;
        setAlitaState({ stateName: 'al', data: key });
    }

    popoverHide = () => {
        this.setState({
            visible: false,
        });
    };
    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
    render() {
        const { user, lang, routeArr, preRoute, currentRoute } = this.state;
        const t = this.props.t;
        const { title = {} } = this.props;
        const nowTitle = title.data ? title.data : localStorage.getItem('title') ? localStorage.getItem('title') : 'TenantsData'
        console.log('nowTitle=>', nowTitle)
        const overlayMenu = (
            <Menu>
                <Menu.Item hidden={lang === 'en-US'} className='Dropdown_new_list'>
                    <a onClick={this.changelang.bind(this, 'en-US')}>
                        {t('English')}
                    </a>
                </Menu.Item>
                <Menu.Item hidden={lang === 'zh-CN'} className='Dropdown_new_list'>
                    <a onClick={this.changelang.bind(this, 'zh-CN')}>
                        {t('Chinese')}
                    </a>
                </Menu.Item>
                <Menu.Item hidden={lang === 'ko-KR'} className='Dropdown_new_list'>
                    <a onClick={this.changelang.bind(this, 'ko-KR')}>
                        {t('Korean')}
                    </a>
                </Menu.Item>
            </Menu>
        );
        const overlayUser = (
            <Menu>
                <Menu.Item className='Dropdown_new_list'>
                    <a>
                        <Icon type="user" style={{ marginRight: 10 }} /><span className="ant-typography-secondary">{user.email}</span>
                    </a>
                </Menu.Item>
                <Menu.Item className='Dropdown_new_list'>
                    <a onClick={this.logout}>
                        <Icon type="poweroff" style={{ marginRight: 10 }} /><span className="ant-typography-secondary">{t('LogOut')}</span>
                    </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <Header className="header-custom"
                style={{ paddingRight: this.props.history.location.pathname === '/initialization' ? 0 : (this.props.collapsed ? 56 : 256) }}>
                <div className={this.props.history.location.pathname === '/initialization' ? '' : 'hidden'} style={{ lineHeight: 'normal' }}>
                    <i className="icon-logo ml-l" style={{ fontSize: '24px' }} />
                </div>
                <div className={this.props.history.location.pathname === '/initialization' ? 'hidden' : ''} style={{ lineHeight: 'normal' }}>
                    <span className='headerNewTitle'>{t(nowTitle)}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    {/* className='none' */}
                    {user.orgName && <span className='companyName'>{user.orgName}</span>}
                    {/* <Dropdown overlay={overlayMenu}>
                        <a style={{ color: '#999999' }}>
                            <span hidden={lang !== 'en-US'}> {t('English')}  <Icon type="down" style={{ fontSize: '10px' }} /></span>
                            <span hidden={lang !== 'zh-CN'}> {t('Chinese')}  <Icon type="down" style={{ fontSize: '10px' }} /></span>
                            <span hidden={lang !== 'ko-KR'}> {t('Korean')}   <Icon type="down" style={{ fontSize: '10px' }} /></span>
                        </a>
                    </Dropdown> */}
                    <div style={{ width: 10 }} />
                    <Dropdown overlay={overlayUser}>
                        <a style={{ padding: '0 10px' }}><img src='/images/new-user.png' /> &nbsp;<Icon type="down" style={{ color: '#999999', fontSize: '12px' }} /></a>
                    </Dropdown>
                    <div style={{ width: 10 }} />
                </div>
            </Header >
        )
    }
}

export default withRouter(connectAlita(['auth', 'title'])(HeaderCustom));
