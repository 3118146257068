import React from "react";

export default {
  "Chinese": "Chinese-Simplified",
  "English": "English",
  "Korean": "Korean",
  "WelcomenFoxitAdminConsole": "Welcome to the Foxit Admin Console",
  "FoxitPDFIntro": "Next generation PDF solutions for the world, with leading - edge technology that powers document intelligence, security, and collaboration services for PDF files.",
  "UserName": "User Name",
  "Password": "Password",
  "RememberMe": "Remember me",
  "password_between_prompt": "Passwords should contain 6-16 characters.",
  "password_required": "Password is required.",
  "confirmPassword_required": "Please confirm your password",
  "email_required": "Email address is required.",
  "email_error": "Please enter a valid email address.",
  "SignIn": "Sign In",
  "SignUp": "Sign Up",
  "AdminConsole": "Foxit Admin Console",
  "TenantsData": "Tenants data",
  "AdvancedSearch": "Advanced search",
  "PurchasedTime": "Purchased Time",
  "ExpiredTime": "Expired Time",
  "SearchCompanyName": "Search company name",
  "TotalCompany": "Total company:{{num}}",
  "CompanyName": "Company name",
  "Admin": "Admin",
  "PurchasedProducts": "Purchased Products",
  "WindowsPhantomPDFStandard": "Windows PhantomPDF Standard",
  "WindowsPhantomPDFBusiness": "Windows PhantomPDF Business",
  "FoxitSignUserModel": "Foxit Sign User Model",
  "FoxitSignEnvelopeModel": "Foxit sign Envelope Model",
  "All Products": "All Products",
  "AdminConsoleExplain": "The Foxit Admin Console is the portal IT administrators can use to manage user accounts and configuration settings for Foxit products and services, as well as an optional internal update server. You can use the Admin Console on Foxit Cloud or a local installation.",
  "MacPhantomPDF": "Mac PhantomPDF",
  "PhantomPDFOnline": "PhantomPDF Online",
  "All": "All",
  "Searchresult": "Search result({{num}})",
  "permanently effective": "permanently effective",
  "SearchFor": "search for {{user}}",
  "New features": "New features",
  "Resolved issues": "Resolved issues",
  "Accessible product":"Accessible product",
  "Submit": "Submit",
}
