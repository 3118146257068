import React, { useState } from 'react';
import { Menu, Icon, Dropdown, Button } from 'antd';
import { Link } from 'react-router-dom';

const getTilte = (title, setAlitaState) => {
  setAlitaState({ stateName: "title", data: title })
  localStorage.setItem('title', title)
}

const renderMenuItem = (item, setAlitaState, t) => {
  return ( // item.route 菜单单独跳转的路由
    <Menu.Item key={item.key}
      onClick={() => { getTilte(item.title, setAlitaState) }}
    >
      <Link to={(item.route || item.key) + (item.query || '')} id={item.id}>
        {item.icon && <i className={item.icon + " icon"} />}
        <span className="nav-text">{t(item.title)}</span>
      </Link>

    </Menu.Item>
  );
};

const renderSubMenu = (item, setAlitaState, t) => {
  return (
    <Menu.SubMenu
      key={item.key}
      title={
        <div>
          <span>
            {item.icon && <i className={item.icon + " icon"} />}
            <span className="nav-text">{t(item.title)}</span>
          </span>
        </div>
      }
    >
      <Menu.ItemGroup title={<span>
        <span className='titleIcon'>{item.icon && <i className={item.icon + " icon"} />}</span>
        <span className='titleTitle'>{t(item.title)}</span>
      </span>} hidden={collapsed ? 1 : 0} className='close_title'>
      </Menu.ItemGroup>
      {item.subs.map(item => renderMenuItem(item, setAlitaState, t))}
    </Menu.SubMenu>
  );
};

export default ({ menus, modules, serverConfig, userIDMode, userWizard, licenseRestriction, t, push, selectedKeys, openKeys, setAlitaState, showWizard, licenseMode, ...props }) => {
  const [dragItems, setDragItems] = useState(menus);
  const divProps = Object.assign({}, props);
  delete divProps.clientHeight;
  return (
    //
    <div>
      {dragItems.map((item, index) => (
        <Menu key={item.key} {...divProps} selectedKeys={selectedKeys} openKeys={openKeys} subMenuOpenDelay={0.5}>
          {item.subs ?
            renderSubMenu(item, setAlitaState, t) :
            renderMenuItem(item, setAlitaState, t)
          }
        </Menu>
      ))}
    </div>

  );
}
