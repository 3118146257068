import React from 'react';
import { Form, Icon, Input, Button, Checkbox, message, Row, Col } from 'antd';
import { connectAlita } from 'redux-alita';
import foxitLogo from '../../style/imgs/foxit.png';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getSuperAdminExist, getCustomAcLog, getCasLoginByTicket, adminExist } from '../../axios/index';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies'
import config from '../../routes/config';
const FormItem = Form.Item;
import { storageGetItem, windowActionFn, storageSetItem } from '../../utils/index';


@withTranslation(['common'])
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.refRoot = React.createRef();
        this.state = {
            deploymentMode: null,
            loading: false,
            formAdminConsole: {
                imageUrl: foxitLogo,
                title: ''
            },
            winAll: false,
            casApiUrl: "",
            serviceUrl: "",
        }
    }

    componentWillMount() {
        const { setAlitaState, history } = this.props;
        const localUser = localStorage.getItem('user');
    }

    componentDidMount() {
        const { history } = this.props;
        this.getServerConfig()
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        this.setState = (state, callback) => { return; };
    }


    handleSubmit = (e) => {
        const { setAlitaState, history } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            const t = this.props.t;
            console.log('dddd=>', err)
            if (!err) {
                this.setState({ loading: true, });
                console.log('Received values of form: ', values);
                setAlitaState({
                    funcName: 'login',
                    params: {
                        email: values.userName,
                        password: values.password,
                        remember: values.remember ? 1 : 0
                    },
                    stateName: 'auth'
                }).then(res => {
                    if ((res.data && res.data.code == 100000)) {
                        // var  ttt = {code:100000,msg:"成功",data:{"userId":"5ec60411603d5c011028a93e",email:"admin@dropjar.com",name:"admin@dropjar.com",role:0,orgName:""}}
                        localStorage.setItem('user', JSON.stringify(res.data))
                        this.setState({ loading: false });
                        history.push('/updatelog');
                    }
                    else {
                        this.setState({ loading: false });
                        message.error(res.data.msg);
                    }
                });
            }
        });
    };

    getServerConfig = () => {
        const { setAlitaState, history } = this.props;
        setAlitaState({
            stateName: 'serverConfig',
            funcName: 'getServerConfig',
        }).then(res => {
            localStorage.setItem('serverConfig', JSON.stringify(res.data.data));
            this.setState({
                deploymentMode: res.data.data.deploymentMode,
                cwsApiUrl: res.data.data.cwsApiUrl,
                os: res.data.data.os,
                casApiUrl: res.data.data.casServerUrl,
            });
        });
    };

    goForgetPassword = () => {
        var openUrl = this.state.cwsApiUrl + '/site/forgetpassword?opentype=onlineview'
        windowActionFn(window, 'open', [openUrl, '_self']);
    };


    render() {
        // console.log("Login isExpires:", this.state.isExpires);
        const { getFieldDecorator } = this.props.form;
        const { deploymentMode, loading, formAdminConsole, os } = this.state;
        const t = this.props.t;
        return (
            <div
                style={{ background: "url(" + require("../../style/imgs/bag-o.png") + ") 0 0 / 100% 100%" }}
            >
                <div className="login">
                    <div className="login-wrap-wrap">
                        <div className="line_2"></div>
                        <div className="line_1"></div>
                        <Row className="login-wrap">
                            <Col className="login-form"  >
                                <div className="login-logo">
                                    <div>
                                        {/*<img src={formAdminConsole.imageUrl} alt="foxitLogo" className="foxit-logo" />*/}
                                        <img src={!!formAdminConsole.imageUrl ? formAdminConsole.imageUrl : foxitLogo} alt="foxitLogo" className="foxit-logo" />
                                        {/*<img src={user} alt="user" className="user-i mb-s mt-s" />*/}
                                    </div>
                                    <p className="title">{!!formAdminConsole.title ? formAdminConsole.title : t('FoxitAdminConsole')}</p>
                                    <p className="explain" >{t('AdminConsoleExplain')}</p>
                                </div>
                                <Form
                                    className="scale_1"
                                    onSubmit={this.handleSubmit}
                                    style={{ maxWidth: '346px' }}
                                >
                                    <FormItem className="item_email">
                                        {getFieldDecorator('userName', {
                                            validateTrigger: 'onBlur',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: t('email_required')
                                                },
                                                {
                                                    pattern: new RegExp(/^[\w-+]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/),
                                                    message: t('email_error')
                                                }
                                            ],
                                        })(
                                            <Input
                                                size="large"
                                                // prefix={<Icon type="mail" style={{ fontSize: 13, color: "#bfbfbf" }} />}
                                                prefix={<div className="iconMail"><svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="15.769" viewBox="0 0 20.5 15.769"> <path id="路径_7" data-name="路径 7" d="M18.827,128H1.171A1.193,1.193,0,0,0,0,129.206v12.857a1.193,1.193,0,0,0,1.172,1.206H18.827A1.193,1.193,0,0,0,20,142.063V129.206A1.193,1.193,0,0,0,18.827,128Zm0,1.206v.359a.577.577,0,0,0-.089.043L10,134.744,1.26,129.608a.58.58,0,0,0-.089-.043v-.359ZM1.171,142.063v-11.13L9.617,135.9a.6.6,0,0,0,.763,0l8.447-4.964v11.13Z" transform="translate(0.251 -127.75)" fill="#f58320" stroke="#f58320" strokeWidth="0.5" /> </svg></div>}
                                                placeholder={t('EmailAddress')}
                                            />
                                        )}
                                    </FormItem>
                                    <FormItem >
                                        {getFieldDecorator('password', {
                                            validateTrigger: 'onBlur',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: t('password_required')
                                                },
                                                {
                                                    min: 6, max: 16,
                                                    message: t('password_between_prompt')
                                                },
                                            ],
                                        })(
                                            <Input
                                                size="large"
                                                prefix={<div className="iconPassword"><svg xmlns="http://www.w3.org/2000/svg" width="20.008" height="23.539" viewBox="0 0 20.008 23.539">
                                                    <g id="组_11" data-name="组 11" transform="translate(-116.364 -46.546)">
                                                        <path id="路径_5" data-name="路径 5" d="M132.841,374.129a1.765,1.765,0,0,1,1.765,1.765v8.239a1.765,1.765,0,0,1-1.765,1.765H119.895a1.765,1.765,0,0,1-1.765-1.765v-8.239a1.765,1.765,0,0,1,1.765-1.765h12.947m0-1.765H119.895a3.531,3.531,0,0,0-3.531,3.531v8.239a3.531,3.531,0,0,0,3.531,3.531h12.947a3.531,3.531,0,0,0,3.531-3.531v-8.239A3.531,3.531,0,0,0,132.841,372.364Z" transform="translate(0 -317.579)" fill="#f58320" />
                                                        <path id="路径_6" data-name="路径 6" d="M285.158,48.311a4.119,4.119,0,0,1,4.119,4.119v2.354h-8.239V52.43a4.119,4.119,0,0,1,4.119-4.119m0-1.765a5.885,5.885,0,0,0-5.885,5.885V56.55h11.77V52.43a5.885,5.885,0,0,0-5.885-5.885Zm0,18.537a.883.883,0,0,1-.883-.883V60.669a.883.883,0,1,1,1.765,0V64.2A.883.883,0,0,1,285.158,65.083Z" transform="translate(-158.79 0)" fill="#f58320" />
                                                    </g>
                                                </svg></div>}
                                                type="password"
                                                placeholder={t('Password')}
                                            />
                                        )}
                                    </FormItem>
                                    <div className="pwdInfo" >
                                        <div >
                                            {getFieldDecorator('remember', {
                                                valuePropName: 'checked',
                                                initialValue: true,
                                            })(
                                                <Checkbox > {t('RememberMe')}</Checkbox>
                                            )}
                                        </div>
                                        {/* <span className="cursor-p text-center"
                                            hidden={os == 'WIN'}
                                            onClick={this.goForgetPassword}
                                        >{t('ForgetPassword')}</span>
                                        <span className="cursor-p text-center"
                                            hidden={os !== 'WIN'}
                                        >
                                            <Link to="/forgotPassword">{t('ForgetPassword')}</Link>
                                        </span> */}
                                    </div>
                                    <div className="mb-m">
                                        <Button size='large' type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%', height: '58px', fontSize: '26px' }}
                                            loading={!deploymentMode || loading}
                                        >
                                            {t('SignIn')}
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connectAlita(['auth', 'serverConfig'])(Form.create()(Login)));
