import React, { PureComponent } from "react";
import { Form, message, Modal, Select, DatePicker, Checkbox } from "antd";
import { withTranslation } from "react-i18next";
import "@wangeditor/editor/dist/css/style.css";
import { i18nChangeLanguage } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import { postUpdateLog } from '../../axios';
import moment from 'moment';
const { Option } = Select;
@withTranslation(["common"])
class EditorComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editor: null,
      editor1: null,
      editorContent: '',
      editorContent1: '',
      dateValue: moment(),
      lang: 'en-US',
      dateString: moment().format("YYYY-MM-DD"),
      check: ['eu', 'us', 'cn', 'ca', 'jp']
    };
  }

  componentWillUnmount() {
    const { editor } = this.state;
    if (editor == null) {
      return;
    }
    editor.destroy();
    this.setState({ editor: null });
  }

  componentDidUpdate() {
    const { editor } = this.state;
    if (editor == null) {
      return;
    }
    const { visible } = this.props;
    if (!visible) {
      this.editor.destroy();
      this.setState({ editor: null });
    }
  }

  handleCancel = (e) => {
    this.props.close();
  };


  handleOk = () => {
    const { editorContent, editorContent1, dateString, lang, check } = this.state;
    var data = {
      "logDate": dateString,
      "features": editorContent1,
      "issues": editorContent,
      "al": lang,
      "region": check
    }
    postUpdateLog(data).then(res => {
      if (res.code === 100000) {
        message.success(res.msg)
        this.props.close(1)
      } else {
        message.error(res.msg)
      }
    })
    console.log("Resolved issues:", editorContent)
    console.log("New features:", editorContent1)
  };

  dateOnChange = (date, dateString) => {
    console.log(date, dateString);
    this.setState({
      dateValue: date,
      dateString: dateString
    })
  }

  handleChange = (e) => {
    console.log("🚀 ~ file: EditorComponent.jsx ~ line 63 ~ EditorComponent ~ e", e)
    this.setState({
      lang: e
    })
  }

  onChange = (checkedValues) => {
    console.log("🚀 ~ file: EditorComponent.jsx ~ line 87 ~ EditorComponent ~ onChange ~ checkedValues", checkedValues)
    this.setState({
      check: checkedValues
    })
  }

  render() {
    const t = this.props.t;
    const { visible } = this.props;
    const {
      editor,
      editor1,
      editorContent,
      editorContent1,
      dateValue,
      lang,
      check
    } = this.state;


    // ----------------------- editor config -----------------------
    const editorConfig = {};
    editorConfig.onCreated = (editor) => {
      this.setState({ editor });
    };
    editorConfig.onChange = (editor) => {
      this.setState({
        editorContent: editor.getHtml(),
      });
    };

    const editorConfig1 = {};
    editorConfig1.onCreated = (editor1) => {
      this.setState({ editor1 });
    };
    editorConfig1.onChange = (editor1) => {
      this.setState({
        editorContent1: editor1.getHtml(),
      });
    };
    i18nChangeLanguage('en');
    // editor.getAllMenuKeys()

    // ----------------------- toolbar config -----------------------
    const toolbarConfig = {
      toolbarKeys: [
        "headerSelect",
        "fontSize",
        "bold",
        "color",
        "bulletedList",
        {
          key: "group-more", // 必填，要以 group 开头
          title: "More", // 必填
          menuKeys: [
            "underline",
            "italic",
            "through",
            "bgColor",
            "emotion",
            "indent",
            "delIndent",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "insertLink",
          ],
        },
        "undo",
        "redo",
      ],
    };
    const toolbarConfig1 = {
      toolbarKeys: [
        "headerSelect",
        "fontSize",
        "bold",
        "color",
        "bulletedList",
        {
          key: "group-more2", // 必填，要以 group 开头
          title: "More", // 必填
          menuKeys: [
            "underline",
            "italic",
            "through",
            "bgColor",
            "emotion",
            "indent",
            "delIndent",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "insertLink",
          ],
        },
        "undo",
        "redo",
      ],
    };

    const options = [
      { label: '欧洲区', value: 'eu' },
      { label: '美国区', value: 'us' },
      { label: '中国区', value: 'cn' },
      { label: '加拿大区', value: 'ca' },
      { label: '日本区', value: 'jp' },
    ];

    return (
      <Modal
        title={t("添加日志")}
        visible={visible}
        onCancel={() => {
          this.props.close();
        }}
        onOk={() => {
          this.handleOk()
        }}
        width={630}
        className="editor-modal"
        maskClosable={false}
      >
        <Form>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            label={t("日期")}
            name="Date"
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={dateValue}
              allowClear={false}
              autoFocus={true}
              onChange={(date, dateString) => { this.dateOnChange(date, dateString) }}
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            label={t("语言")}
            name="Date"
          >
            <Select value={lang} style={{ width: 120 }} onChange={(e) => this.handleChange(e)}>
              <Option value="en-US">英文</Option>
              <Option value="zh-CN">中文</Option>
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            label={t("New features")}
            name="features"
            className="weditor"
          >
            <div style={{ border: "1px solid #ccc", width: "460px" }} >
              <Toolbar editor={editor1} defaultConfig={toolbarConfig1} />
              <Editor
                defaultConfig={editorConfig1}
                defaultHtml={editorContent1}
                style={{ height: "160px" }}
              />
            </div>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            label={t("Resolved issues")}
            name="issues"
            className="weditor"
          >
            <div style={{ border: "1px solid #ccc", width: "460px" }} >
              <Toolbar editor={editor} defaultConfig={toolbarConfig} />
              <Editor
                defaultConfig={editorConfig}
                defaultHtml={editorContent}
                style={{ height: "160px" }}
              />
            </div>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            label={t("region")}
            name="region"
          >
            <Checkbox.Group options={options} defaultValue={check} onChange={this.onChange} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default EditorComponent;
