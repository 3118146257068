/**
 * http通用工具函数
 */
import axios from 'axios';
import { message } from 'antd';

/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */

export const get = ({ url, params, msg = 'Interface exception', headers }) =>
    axios.get(url, { params }, headers).then(res => res.data).catch(err => {
        message.warn(msg);
        throw err;
    });


// 分页get方法
export const getList = ({ url, params, msg = 'Interface exception', headers }) =>
    axios.get(url, { params }, headers).then(res => {
        var headers = { per_total: 0, per_page: 0, page: 0, page_count: 0 }
        headers.per_page = parseInt(res.headers['x-pagination-per-page'])
        headers.per_total = parseInt(res.headers['x-pagination-total-count'])
        headers.page_count = parseInt(res.headers['x-pagination-page-count'])
        headers.page = parseInt(res.headers['x-pagination-current-page'])
        res.data.headers = headers
        return res.data
    }).catch(err => {
        message.warn(msg);
        throw err;
    });


/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const post = ({ url, data, msg = 'Interface exception', headers}) =>
    axios.post(url, data, headers).then(res => res.data).catch(err => {
        message.warn(msg);
        throw err;
    });


/**
 * 公用put请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const put = ({ url, data, msg = 'Interface exception', headers }) =>
    axios.put(url, data, headers).then(res => res.data).catch(err => {
        message.warn(msg);
        throw err;
    });

/**
 * 公用delete请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const DELETE = ({ url, data, msg = 'Interface exception', headers }) =>
    axios.delete(url, { data }, headers).then(res => res.data).catch(err => {
        message.warn(msg);
        throw err;
    });
