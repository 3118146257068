import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect,BrowserRouter} from 'react-router-dom';
import NotFound from './components/pages/NotFound';
import Login from './components/pages/Login';
import App from './App';
import SignUp from './components/pages/SignUp';


export default () => (

    <BrowserRouter>
        <Switch>
            <Route path="/app" render={() =><App />} />
            <Route exact path="/" render={() =><Login/>} />
            <Route path="/404" render={() =><NotFound />} />   
            <Route path="/sign-up" render={() =><SignUp/>} />
            <Route render={() =><App/>} />
        </Switch>
    </BrowserRouter>

)
