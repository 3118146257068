
// 获取url的参数
export const queryString = () => {
    let _queryString = {};
    const _query = window.location.search.substr(1);
    const _vars = _query.split('&');
    _vars.forEach((v, i) => {
        const _pair = v.split('=');
        if (!_queryString.hasOwnProperty(_pair[0])) {
            _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
        } else if (typeof _queryString[_pair[0]] === 'string') {
            const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])];
            _queryString[_pair[0]] = _arr;
        } else {
            _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
        }
    });
    return _queryString;
};

// 数组删除指定元素
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

// 时间扩展 (格式校验)
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 -
        RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ?
            (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

export const convertUTCDateToLocalDate = (UTCDateString) => {
    if (!UTCDateString) { return null }
    //UTCDateString为要转换的时间(Date类型)
    // 获取时区
    var i = new Date().getTimezoneOffset() / 60
    if (typeof i !== 'number') return;
    var len = new Date(Date.parse(UTCDateString.replace(/-/g, "/"))).getTime();
    //得到现在的格林尼治时间
    var LocalDate = len - 3600000 * i;
    return new Date(LocalDate).Format("yyyy-MM-dd hh:mm:ss")
}

export const convertLocalDateToUTCDate = (UTCDateString) => {
    if (!UTCDateString) { return null }
    //UTCDateString为要转换的时间(Date类型)
    // 获取时区
    var i = new Date().getTimezoneOffset() / 60
    if (typeof i !== 'number') return;
    var len = new Date(Date.parse(UTCDateString.replace(/-/g, "/"))).getTime();
    //得到现在的格林尼治时间
    var LocalDate = len + 3600000 * i;
    return new Date(LocalDate).Format("yyyy-MM-dd hh:mm:ss")
}

export const isObjectValueEqual = (a, b) => {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]

        var propA = a[propName]
        var propB = b[propName]
        if ((typeof (propA) === 'object')) {
            if (this.isObjectValueEqual(propA, propB)) {
                return true
            } else {
                return false
            }
        } else if (propA !== propB) {
            return false
        } else { }
    }
    return true
}




/**
 * 函数功能简述
 *  dataSelete （Array）:
 *  [{
 *      key: "5d6b58ece6f19d11ca5ce734",
 *      UserName: "",
 *      Email: "jy13@getnada.com",
 *      ActivationStatus: "0/1",
 *      AssignedTime: "2019-09-01 13:36:44"
 *  }]
 *
 *  columns  （Array）:
 *  ["UserName", "Email", "ActivationStatus", "AssignedTime"]
 *
 *  会过滤掉columns没有的项
 */
export function exportUser(dataSelete, columns, callback) {
    console.info("exportUser :", dataSelete, columns, !!callback)
    // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
    let str = `${columns.join(",")}\n`;
    //增加\t为了不让表格显示科学计数法或者其他格式
    dataSelete.map((data, index) => {
        for (let item in data) {
            if (columns.indexOf(item) != -1) {
                str += `${data[item] + '\t'},`;
            }
        }
        str += '\n';
    });

    //encodeURIComponent解决中文乱码
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
    //通过创建a标签实现
    let link = document.createElement("a");
    link.href = uri;
    //对下载的文件命名
    link.download = new Date().toISOString().substring(0, 10) + "-License.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
        callback && callback()
    }, 1000);
}

// 将url图片转为base64图片
export function getUrl_Base64(url, callback) {
    //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
    let Img = new Image(),
      dataURL = '';
    Img.src = url +"?v=" + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
    Img.setAttribute("crossOrigin", 'Anonymous') // 解决控制台跨域报错的问题
    Img.onload = function () { //要先确保图片完整获取到，这是个异步事件
        let canvas = document.createElement("canvas"), //创建canvas元素
          width = Img.width, //确保canvas的尺寸和图片一样
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
        dataURL = canvas.toDataURL('image/jpeg'); //转换图片为dataURL
        callback && callback(dataURL)
    };
}


// 封装window open
export function windowActionFn(_window, fnName, args) {
    if (!_window && typeof _window[fnName] !== 'function') return;
    return _window[fnName](...args);
}

// 封装缓存获取
export function storageGetItem(storage, key) {
    if (!storage) return null;
    return storage.getItem(key);
}

//封装缓存删除

export function storageRemoveItem(storage, key) {
    if (!storage) return null;
    return storage.removeItem(key);
}
//封装缓存清除
export function storageClear(storage) {
    storage && storage.clear();
}

// 缓存写入
export function storageSetItem(storage, key, value) {
    storage && key && storage.setItem(key, value);
}