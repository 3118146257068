import React from 'react';
import { Form, Icon, Input, Button, Checkbox, message, Row, Col } from 'antd';
import { connectAlita } from 'redux-alita';
import foxitLogo from '../../style/imgs/foxit.png';
import table from '../../style/imgs/table.png';
import user from '../../style/imgs/user.png';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {} from "../../axios";
import cookie from 'react-cookies';
import i18n from "i18next";
import { storageGetItem, windowActionFn, storageSetItem } from '../../utils/index';
const FormItem = Form.Item;

@withTranslation(['common'])
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.refRoot = React.createRef();
    this.state = {
      deploymentMode: null,
      loading: false,
    }
  }
  componentDidMount() {
    const { setAlitaState,history } = this.props;
    this.getServerConfig();
    this.navLang();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const { setAlitaState } = this.props;
      const { deploymentMode } = this.state;
      const t = this.props.t;
      const {  history } = this.props;
      if (!err && deploymentMode) {
        if(values.confirmPassword != values.password){
          message.error(t('KeepPassword'));
          return
        }
        this.setState({ loading: true, });
        console.log('Received values of form: ', values);
        if (deploymentMode && deploymentMode == "on-premise") {
          setAlitaState({
            funcName: 'signUp',
            params: {
              email: values.userName,
              password: values.password
            },
            stateName: 'auth'
          }).then(res => {
            if ((res.data && res.data.code != 100000)) {
              if(res.data.code == 100030){
                // history.push('/');
                message.error(t('AdministratoRegistered'))
                setTimeout(() => {
                  windowActionFn(window, 'open', ['/', '_self']);
                }, 1000);
              }else{
                message.error(res.msg);
              }
            } else{
              setAlitaState({
                funcName: 'login',
                params: {
                  email: values.userName,
                  password: values.password
                },
                stateName: 'auth'
              }).then(res=>{
                this.setState({ loading: false, });
                if ((res.data && res.data.code != 100000)) {
                  message.error(res.msg);
                }else{
                  this.goDashboard()
                }
              })
            }
          });
        } else {

        }
      }
    });
  };
  goDashboard = () => {
    const { auth: nextAuth = {}, history,setAlitaState} = this.props;
    var os = 'WIN';
    var appType = 'wbiu';
    os = localStorage.getItem('serverConfig') ? JSON.parse(localStorage.getItem('serverConfig')).os : os;
    appType = localStorage.getItem('serverConfig') ? JSON.parse(localStorage.getItem('serverConfig')).appType : appType;
    var winALL = (os == 'WIN' && appType == 'wbiu')
    setAlitaState({ stateName: "winAll", data: winALL })
    if ((nextAuth.data && nextAuth.data.code == 100000)) { // 判断是否登陆
      localStorage.setItem('user', JSON.stringify(nextAuth.data));
      console.log('winALLsignup:',winALL)
      if(winALL){
        localStorage.setItem('title','Configuration')
        history.push('/internalupdate/configuration');
      }else{
        history.push('/dashboard');
      }
    }
  }
  getServerConfig = () => {
    const { setAlitaState } = this.props;
    setAlitaState({
      stateName: 'serverConfig',
      funcName: 'getServerConfig',
    }).then(res => {
      localStorage.setItem('serverConfig', JSON.stringify(res.data.data));
      this.setState({
        deploymentMode: res.data.data.deploymentMode,
        cwsApiUrl: res.data.data.cwsApiUrl,
      },()=>{
        console.log('deploymentMode',this.state.deploymentMode,!this.state.deploymentMode);
      });
    });
  };
  goForgetPassword = () => {};

// 同步浏览器语言
navLang = ()=>{
        var type = navigator.appName;
        　　if (type == "Netscape"){
            　　var lang = navigator.language;
        　　}else{
            　　var lang = navigator.userLanguage;//支持IE5+
        　　};
        　　var lang = lang.substr(0, 2);
        　　if (lang == "zh"){
            　　 lang = 'zh-CN'
        　　}else if (lang == "en"){
            　　 lang = 'en-US'
        　　}else{
                lang = 'en-US'
           }
          lang = (cookie.load('al')=='en-US'||cookie.load('al')=='zh-CN'||cookie.load('al')=='ko-KR')?cookie.load('al'):lang
          i18n.init({
              lng: lang
          });
          var url = window.location.host.split('.')
          var domain = url[1]+'.'+url[2]
          cookie.save('al',lang,{path:'/',domain:domain})
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { deploymentMode, loading } = this.state;
    const t = this.props.t;
    return (
      <div
        style={{ background: "url(" + require("../../style/imgs/bag-o.png") + ") 0 0 / 100% 100%" }}
      >
        <div className="signUp">
          <div className="signUp-wrap-wrap">
            <div className="line_2"></div>
            <div className="line_1"></div>
            <Row className="signUp-wrap">
              <Col className="signUp-form"  >
                <div className="signUp-logo">
                  <div>
                    <img src={foxitLogo} alt="foxitLogo" className="foxit-logo" />
                  </div>
                  <p className="title">{t('CreateAdministrator')}</p>
                </div>
                <Form
                  className="scale_1"
                  onSubmit={this.handleSubmit}
                  style={{ maxWidth: '346px' }}
                >
                  <FormItem  className="item_email">
                    {getFieldDecorator('userName', {
                      validateTrigger:'onBlur',
                      rules: [
                        {
                          required: true,
                          message: t('email_required')
                        },
                        {
                          pattern: new RegExp(/^[\w-+]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/),
                          message: t('email_error')
                        }
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={<div className="iconMail"><svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="15.769" viewBox="0 0 20.5 15.769"> <path id="路径_7" data-name="路径 7" d="M18.827,128H1.171A1.193,1.193,0,0,0,0,129.206v12.857a1.193,1.193,0,0,0,1.172,1.206H18.827A1.193,1.193,0,0,0,20,142.063V129.206A1.193,1.193,0,0,0,18.827,128Zm0,1.206v.359a.577.577,0,0,0-.089.043L10,134.744,1.26,129.608a.58.58,0,0,0-.089-.043v-.359ZM1.171,142.063v-11.13L9.617,135.9a.6.6,0,0,0,.763,0l8.447-4.964v11.13Z" transform="translate(0.251 -127.75)" fill="#f58320" stroke="#f58320" strokeWidth="0.5"/> </svg></div>}
                        placeholder={t('EmailAddress')}
                        tabIndex={1}
                      />
                    )}
                  </FormItem>
                  <FormItem >
                    {getFieldDecorator('password', {
                      validateTrigger:'onBlur',
                      rules: [
                        {
                          required: true,
                          message: t('password_required')
                        },
                        {
                          min: 6, max: 16,
                          message: t('password_between_prompt')
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={<div className="iconPassword"><svg xmlns="http://www.w3.org/2000/svg" width="20.008" height="23.539" viewBox="0 0 20.008 23.539">
                          <g id="组_11" data-name="组 11" transform="translate(-116.364 -46.546)">
                            <path id="路径_5" data-name="路径 5" d="M132.841,374.129a1.765,1.765,0,0,1,1.765,1.765v8.239a1.765,1.765,0,0,1-1.765,1.765H119.895a1.765,1.765,0,0,1-1.765-1.765v-8.239a1.765,1.765,0,0,1,1.765-1.765h12.947m0-1.765H119.895a3.531,3.531,0,0,0-3.531,3.531v8.239a3.531,3.531,0,0,0,3.531,3.531h12.947a3.531,3.531,0,0,0,3.531-3.531v-8.239A3.531,3.531,0,0,0,132.841,372.364Z" transform="translate(0 -317.579)" fill="#f58320"/>
                            <path id="路径_6" data-name="路径 6" d="M285.158,48.311a4.119,4.119,0,0,1,4.119,4.119v2.354h-8.239V52.43a4.119,4.119,0,0,1,4.119-4.119m0-1.765a5.885,5.885,0,0,0-5.885,5.885V56.55h11.77V52.43a5.885,5.885,0,0,0-5.885-5.885Zm0,18.537a.883.883,0,0,1-.883-.883V60.669a.883.883,0,1,1,1.765,0V64.2A.883.883,0,0,1,285.158,65.083Z" transform="translate(-158.79 0)" fill="#f58320"/>
                          </g>
                        </svg></div>}
                        type="password"
                        placeholder={t('Password')}
                        tabIndex={2}
                      />
                    )}
                  </FormItem>
                  <FormItem  >
                    {getFieldDecorator('confirmPassword', {
                      validateTrigger:'onBlur',
                      rules: [
                        {
                          required: true,
                          message: t('confirmPassword_required')
                        },
                        {
                          min: 6, max: 16,
                          message: t('password_between_prompt')
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={<div className="iconPassword"><svg xmlns="http://www.w3.org/2000/svg" width="20.008" height="23.539" viewBox="0 0 20.008 23.539">
                          <g id="组_11" data-name="组 11" transform="translate(-116.364 -46.546)">
                            <path id="路径_5" data-name="路径 5" d="M132.841,374.129a1.765,1.765,0,0,1,1.765,1.765v8.239a1.765,1.765,0,0,1-1.765,1.765H119.895a1.765,1.765,0,0,1-1.765-1.765v-8.239a1.765,1.765,0,0,1,1.765-1.765h12.947m0-1.765H119.895a3.531,3.531,0,0,0-3.531,3.531v8.239a3.531,3.531,0,0,0,3.531,3.531h12.947a3.531,3.531,0,0,0,3.531-3.531v-8.239A3.531,3.531,0,0,0,132.841,372.364Z" transform="translate(0 -317.579)" fill="#f58320"/>
                            <path id="路径_6" data-name="路径 6" d="M285.158,48.311a4.119,4.119,0,0,1,4.119,4.119v2.354h-8.239V52.43a4.119,4.119,0,0,1,4.119-4.119m0-1.765a5.885,5.885,0,0,0-5.885,5.885V56.55h11.77V52.43a5.885,5.885,0,0,0-5.885-5.885Zm0,18.537a.883.883,0,0,1-.883-.883V60.669a.883.883,0,1,1,1.765,0V64.2A.883.883,0,0,1,285.158,65.083Z" transform="translate(-158.79 0)" fill="#f58320"/>
                          </g>
                        </svg></div>}
                        type="password"
                        placeholder={t('ConfirmPassword')}
                        tabIndex={3}
                      />
                    )}
                  </FormItem>
                  <div className="wrapBtn">
                    <Button size='large' type="primary" htmlType="submit" className="signUp-form-button" style={{ width: '100%',height:'58px',fontSize:'26px',marginTop:'15px'}}
                            loading={!deploymentMode || loading}
                    >
                      {t('SignUp')}
                    </Button>
                  </div>
                  <div className="SignUpGuide" dangerouslySetInnerHTML={{__html:t('SignUpGuide')}}></div>
                </Form>

              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connectAlita(['auth', 'serverConfig','winAll'])(Form.create()(SignUp)));
