/**
 * 路由组件出口文件
 */
// import Loadable from 'react-loadable';
import TenantsData from './tenantsdata/TenantsData'
import UpdateLog from './updateLog/UpdateLog'
import DeleteUsersList from './faileDeleteUsers/DeleteUsersList'
import UpdateContactInfo from './updateContactInfo/UpdateContactInfo'
import ExistingLicense from './existingLicenses/ExistingLicenses'
export default {
    TenantsData,
    UpdateLog,
    DeleteUsersList,
    UpdateContactInfo,
    ExistingLicense
}
